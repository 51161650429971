import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Nav  from '../components/nav'
import Image from 'gatsby-image'
import TextLoop from "react-text-loop";

import Instagram from "../assets/instagram.svg";
import Linkedin from "../assets/linkedin.svg";
import Github from "../assets/github.svg";
import Dribbble from "../assets/dribbble.svg";

import '../styles/about.scss'

const BlogPost = props => {
  return (
    <div className="blogPost">
      <a href={props.link} target="_blank" rel="noreferrer">
        <h3 className="blogTitle">/ {props.title}</h3>
        <p className="blogDescription">{props.description}</p>
      </a>
    </div>
  )
}

const ResumeBlock = ({headline, date, company, details}) => {
  return (
    <div className="resumeBlock">
      <div className="resumeBlockHeadline">
        <h3>{headline}</h3>
        <p className="resumeBlockDate">{date}</p>
      </div>
      <h4>{company}</h4>
      <div>
        <ul>
          {
            details.map(detail => (
              <li>{detail}</li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

const IndexPage = props => {
  const blogData = useStaticQuery(graphql`
      query aboutBlogPostQuery {
          allSanityPost(sort: {fields: publishedAt, order: DESC}, limit: 3) {
              edges {
                  node {
                      id
                      title
                      description
                      url
                  }
              }
          }

          profileImage: file(relativePath: { eq: "varun.jpeg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }           
  `)

  const blogPostArray = blogData.allSanityPost.edges;
  const profileImage = blogData.profileImage.childImageSharp.fluid;
  console.log(profileImage)

  return (
    <Layout>
      <SEO/>
      <Nav scrollPoint="#aboutHero"/>
      <div id="aboutHero">
        <div className="container">
          <Image fluid={profileImage} className="profilePic"/>
          <div className="profileHeader">
            <h1>Varun Khatri</h1>
            <TextLoop 
              interval={1500} 
              springConfig={{ stiffness: 220, damping: 15 }} 
              className="changingTag">
                <p>Interaction Designer</p>
                <p>Web Developer</p>
                <p>Video Game Enthusiast</p>
                <p>Graphic Novel Lover</p>
            </TextLoop>
            
            <div className="social-media">
              <a href="https://www.linkedin.com/in/varunmk/" target="_blank" rel="noreferrer"><Linkedin/></a>
              <a href="https://dribbble.com/thevarunkhatri" target="_blank" rel="noreferrer"><Dribbble/></a>
              <a href="https://github.com/thevarunkhatri" target="_blank" rel="noreferrer"><Github/></a>
              <a href="https://www.instagram.com/thevarunkhatri/?hl=en" target="_blank" rel="noreferrer"><Instagram/></a>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutBody container">
        <div className="blogPostSection">
          <h2>Latest Blog Posts</h2>
            {
              blogPostArray.map(edge => 
                  (
                      <BlogPost
                          key={edge.node.id} 
                          title={edge.node.title}
                          description={edge.node.description}
                          link={edge.node.url}/>
                  )
              )
            }
        </div>
        <div className="aboutMain">
          <div className="aboutSection bio">
            <h2>About Me</h2>
            <p className="aboutPara">Hi, I’m Varun, an Indo-Canadian interaction designer studying User Experience Design at <a href="https://www.scad.edu/" target="_blank" rel="noreferrer">/SCAD</a>. Despite being at an art school, I am only learning to accept myself as a designer because of my technical mind. Nonetheless, my curiosity to learn just about anything brought me into the world of design.</p>
            <p className="aboutPara">My fascination with design lies somewhere near the intersection of psychology, art and technology. Areas of interest that I would enjoy working on include, but not exclusively: game UX, voice AI, smart home technology, and tools for developers + designers.</p>
            <p className="aboutPara">Outside of design, you can find me playing video games both for fun and as “literature”,  at my <a href="https://neighborhoodcomics.com/" target="_blank" rel="noreferrer">/local comicbook shop</a> picking up each week’s releases, writing my thoughts on <a href="https://medium.com/@thevarunkhatri" target="_blank" rel="noreferrer">/Medium</a> (despite many not getting published) and trying to read more often.</p>
          </div>

          <div className="aboutSection">
            <h2>Education</h2>
            <ResumeBlock
              headline="User Experience Design, BFA"
              date="09/2018 - 05/2022"
              company="Savannah College of Art and Design"
              details={["4.0 GPA | Dean's List Every Quarter", "Minor in Industrial Design"] }
              />
          </div>
          <div className="aboutSection">
            <h2>Experience</h2>
            <ResumeBlock
              headline="SCADpro - Sponsored Course"
              date="09/2020 - 11/2020"
              company="The Coca-Cola Company X AMC Theatres"
              details={["SCAD collaborated with Coca-Cola and AMC to research and develop concepts to improve the in-theater experience."]}
              />
            <ResumeBlock
              headline="Orientation Assistant"
              date="08/2019 - 09/2020"
              company="Savannah College of Art and Design"
              details={["Guided students and families through SCAD’s Fall and Winter Orientation by serving as a resource", "Assisted Student Success Advisors in assigned 10-week long First Year Experience classes by helping students with any concerns and by giving presentations on student involvement"]}
              />
          </div>
        </div>
      </div>
      <footer className="aboutFooter">
        <div className="container">
          <div className="social-media">
            <a href="https://www.linkedin.com/in/varunmk/" target="_blank" rel="noreferrer"><Linkedin/></a>
            <a href="https://dribbble.com/thevarunkhatri" target="_blank" rel="noreferrer"><Dribbble/></a>
            <a href="https://github.com/thevarunkhatri" target="_blank" rel="noreferrer"><Github/></a>
            <a href="https://www.instagram.com/thevarunkhatri/?hl=en" target="_blank" rel="noreferrer"><Instagram/></a>
          </div>

          <p>Website hand-coded by yours truly.</p>
          <p>(c) Varun Khatri 2020</p>
        </div>
      </footer>
    </Layout>
  )
}

export default IndexPage
